import React, { useEffect } from "react";
import { Link } from "gatsby";
import { Button } from "antd";
import { get, first, compact } from "lodash";
import PropTypes from "prop-types";

import { removeEmptyKeys, removePreloader } from "../../helpers";
import { useThemeContext } from "../../components/Layout/ThemeContext";
import Pages from "../../components/Pages";
import seoData from "../../components/SEO/data";

import "./404.scss";

export default function Page404({ location }) {
	const { theme } = useThemeContext();

	const page_types = {
		news: "DynamicNews",
		articles: "DynamicArticles",
		pages: "DynamicTextPages",
	};

	const path = compact(get(location, "pathname", "").split("/"));

	const component = page_types[first(path)];

	if (typeof window !== "undefined") {
		window.Sentry.captureException(`Missing page: ${get(location, "href", "")}. Component: ${component}`);
	}

	useEffect(() => {
		removePreloader();
	}, []);

	return (
		<Pages entity={get(seoData, "page404", {})}>
			<div className={"container"}>
				<div className={"row block-404"}>
					<div className={"d-flex col-12 justify-content-center flex-column align-items-center py-5"}>
						<h1
							className={"block-404__title"}
							style={removeEmptyKeys({
								fontFamily: theme.fontFamily,
								lineHeight: theme.lineHeight.h1,
								color: theme.color.text,
							})}
						>
							404
						</h1>
						<h2>
							Эта страница не найдена
						</h2>
						<Button className={" mt-5"} type={"primary"}>
							<Link to={"/"} className={"text"}>На главную страницу</Link>
						</Button>
					</div>
				</div>
			</div>
		</Pages>
	);
}

Page404.propTypes = {
	location: PropTypes.object,
};

Page404.displayName = "Page404";

