import React, { useEffect, useState } from "react";
import { graphql, Link } from "gatsby";
import { get, compact, last } from "lodash";
import PropTypes from "prop-types";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import moment from "moment";

import { ContentBlock, MoreTopics } from "../../../components/Blocks";
import Contents from "../../../components/Contents";
import Pages from "../../../components/Pages";
import { makeUrl, removeEmptyKeys, removePreloader, sorter } from "../../../helpers";
import Breadcrumbs from "../../../components/Layout/Breadcrumbs";
import { Share } from "../../../widgets";
import { ARTICLES_MAX_UPDATE, ARTICLES_CONTENT } from "../../../queries/queries.graphql";
import {
	MetaMainEntity,
	MetaMainEntityOfPage,
	MetaDescription,
	MetaDatePublished,
	MetaImage,
	MetaAuthor,
	MetaName,
	MetaTelephone,
	MetaAddress,
	MetaDateModified,
	MetaIdentifier,
} from "../../../components/Meta";
import Page404 from "../../404";
import { useThemeContext } from "../../../components/Layout/ThemeContext";

export const query = graphql`
	query media_articlesDataQuery($slug: String!) {
		hasura {
			...ArticlesContent
		}
	}
`;

export default function Article({ location, data, previewData }) {
	const { theme } = useThemeContext();

	const [article, setArticle] = useState(get(data, "hasura.media_articles[0]", {}));

	const maxUpdated = new Date(get(data, "hasura.media_articles_aggregate.aggregate.max.updated_at", new Date()));

	const previewPath = get(previewData, "slug", "");
	const currentPath = last(compact(get(location, "pathname", "").split("/")));

	const path = previewPath || currentPath;

	const dateNowFormated = +new Date();
	const dateNow = moment().format();

	const articleOptions = {
		variables: {
			location: path,
			dateNow,
		},
		displayName: "ArticleQuery",
		fetchPolicy: "cache-and-network",
		ssr: false,
	};
	const articleOptionsMaxUpdate = {
		variables: {
			location: path,
		},
		displayName: "ArticleQuery",
		fetchPolicy: "cache-and-network",
		ssr: false,
	};

	const { loading: maxUpdateLoading, data: maxUpdateData, error: maxUpdateError } = useQuery(ARTICLES_MAX_UPDATE, articleOptionsMaxUpdate);

	const [loadArticle, { called: articleCalled, loading: articleLoading, data: articleData, error: articleError }] = useLazyQuery(ARTICLES_CONTENT, articleOptions);

	useEffect(() => {
		const currentMaxUpdated = new Date(get(maxUpdateData, "media_articles_aggregate.aggregate.max.updated_at", new Date()));

		if (maxUpdateData && !maxUpdateError) {
			if (+currentMaxUpdated !== +maxUpdated) {
				loadArticle();
			} else {
				removePreloader();
			}
		} else if (maxUpdateError) {
			console.error("Invalid load ArticleMaxUpdate", { maxUpdateError });
			removePreloader();
		}
	}, [maxUpdateLoading]);

	useEffect(() => {
		if (articleData && !articleError) {
			setArticle(get(articleData, "media_articles[0]", previewPath ? null : article));
			removePreloader(articleCalled);
		} else if (articleError) {
			console.error("Invalid load ArticleData", { articleError });
			removePreloader();
		}
	}, [articleLoading]);

	const slug = get(article, "slug", "");
	const title_full = get(article, "title_full", "");
	const page_title_full = get(article, "page_title_full", "");
	const page_title_short = get(article, "page_title_short", "");
	const content_blocks = get(article, "content_blocks", []);
	const main_image_preview = get(article, "main_image_preview", {});
	const main_image = get(article, "main_image", {});
	const age_rating = get(article, "age_rating", "");
	const author = get(article, "author", "");
	const actualDate = get(article, "updated_at", new Date());
	const published_at = get(article, "published_at", "");
	const authorMicrodata = get(article, "author.name", "");
	const updated_at = get(article, "updated_at", new Date());
	const articleId = get(article, "id", 0);
	const linkToCalendar = makeUrl.day({
		currentDay: moment(published_at).format("YYYY-MM-DD"),
		type: "calendar",
	});
	const hidePublishedAt = get(article, "settings.hide_published_at", false);

	const url = makeUrl.mediaArticle({ slug });
	const imageSrc = get(main_image_preview, "src", get(main_image, "src", ""));

	const authorStyle = {
		textAlign: "right",
		fontStyle: "italic",
	};

	const themeTextStyle = removeEmptyKeys({
		fontFamily: theme.fontFamily,
		fontSize: theme.fontSize.text,
		lineHeight: theme.lineHeight.text,
		color: theme.color.text,
	});

	const category = get(article, "category") === null ? {} : get(article, "category");

	const linkedMediaArticleBack = get(article, "mediaArticles", [])
		.filter(item => +new Date(get(item, "mediaArticle.published_at", "")) <= dateNowFormated)
		.map(item => ({
			path: makeUrl.mediaArticle(get(item, "mediaArticle", {})),
			date: !get(item, "mediaArticle.settings.hide_published_at", false) ? new Date(get(item, "mediaArticle.issued_at", "")).toLocaleDateString() : null,
			title: get(item, "mediaArticle.title_full", ""),
			src: get(item, "mediaArticle.main_image.src", ""),
			issued_at: new Date(get(item, "mediaArticle.issued_at", "")),
		}),
		);

	const linkedMediaArticleFeature = get(article, "linkedMediaArticles", [])
		.filter(item => +new Date(get(item, "linkedMediaArticle.published_at", "")) <= dateNowFormated)
		.map(item => ({
			path: makeUrl.mediaArticle(get(item, "linkedMediaArticle", {})),
			date: !get(item, "linkedMediaArticle.settings.hide_published_at", false) ? new Date(get(item, "linkedMediaArticle.issued_at", "")).toLocaleDateString() : null,
			title: get(item, "linkedMediaArticle.title_full", ""),
			src: get(item, "linkedMediaArticle.main_image.src", ""),
			issued_at: new Date(get(item, "linkedMediaArticle.issued_at", "")),
		}),
		);

	const linkedNews = get(article, "linkedNews", [])
		.filter(item => +new Date(get(item, "news.published_at", "")) <= dateNowFormated)
		.map(item => ({
			path: makeUrl.news(get(item, "news", {})),
			date: !get(item, "news.settings.hide_published_at", false) ? new Date(get(item, "news.issued_at", "")).toLocaleDateString() : null,
			title: get(item, "news.title_full", ""),
			src: get(item, "news.main_image.src", ""),
			issued_at: new Date(get(item, "news.issued_at", "")),
		}),
		);

	const linkedArticles = [...linkedMediaArticleBack, ...linkedMediaArticleFeature, ...linkedNews].sort((a, b) => sorter(b.issued_at, a.issued_at));

	return article ? (
		<Pages entity={article} url={url}>
			<ContentBlock key={"breadcrumbs"}>
				<div className="container">
					<Breadcrumbs
						currentLabel={title_full}
						pageContext={
							{
								item: {
									title_full: "Все статьи",
									slug: "articles",
								},
								item2: {
									title_full: get(category, "title_full", ""),
									slug: get(category, "slug", ""),
								},
							}
						}
					/>
				</div>
			</ContentBlock>
			<ContentBlock key={"media-content"} mount={content_blocks && !!content_blocks.length}>
				<div className={"container"} itemScope itemType="https://schema.org/Article">
					<MetaIdentifier content={articleId} />
					<MetaMainEntity content={url} />
					<MetaMainEntityOfPage content={url} />
					<MetaDescription content={get(article, "teaser", "")} />
					<MetaAuthor content={authorMicrodata} />
					<div itemProp="publisher" itemScope itemType="https://schema.org/Organization">
						<MetaName content={"АНО «УК «Саровско-Дивеевский кластер»"} />
						<MetaTelephone />
						<div itemProp="logo" itemScope itemType="https://schema.org/ImageObject">
							<link itemProp="url image" href={"https://storage.yandexcloud.net/serafim-uploads/content/2020/02/06/logo_4288d97f-7963-4fba-a55c-de6d3a56aa88.png"} />
						</div>
						<MetaAddress content={"г.Нижний Новгород, территория Кремль, к.14."} />
					</div>
					<MetaDateModified content={updated_at} />
					<MetaImage content={imageSrc} />
					<MetaDatePublished content={moment(get(article, "issued_at", "")).format("YYYY-MM-DD")} />

					<div className={"h1 article_title"}>
						<h1 itemProp="headline"
							style={themeTextStyle}
						>
							{page_title_full}
						</h1>
					</div>
					<div className={"d-flex justify-content-between"}>
						<div
							className={"text-block__subtitle_category"}
							itemProp="articleSection"
							style={themeTextStyle}
						>
							{
								category && (
									<Link to={makeUrl.allArticlesBySlug(category)}>
										{get(article, "category.title_full", "")}
									</Link>
								)
							}
							{
								age_rating && (
									<span className={"age_rating m-3"}>
										{age_rating}
									</span>
								)
							}
						</div>
						{
							!hidePublishedAt && published_at && (
								<div
									className={"text-block__subtitle_date"}
									content={moment(published_at).format("YYYY-MM-DD")}
									style={themeTextStyle}
								>
									<Link to={linkToCalendar}>
										{moment(published_at).locale("ru").format("DD.MM.YYYY")}
									</Link>
								</div>
							)
						}
					</div>
					<Contents items={content_blocks} date={actualDate} articleBody={"articleBody"}/>
					{
						author && (
							<div
								className={"mb-5"}
								style={Object.assign({}, authorStyle, themeTextStyle)}
								itemProp="author"
							>
								{get(author, "name", "")}
							</div>
						)
					}
				</div>
			</ContentBlock>

			<MoreTopics items={linkedArticles} />

			<div className={"container"}>
				<Share
					url={url}
					pageTitleShort={page_title_short}
					pageTitleFull={page_title_full}
					imageSrc={imageSrc}
				/>
			</div>
		</Pages>
	) : (
		<Page404 location={location} />
	);
}

Article.propTypes = {
	data: PropTypes.object,
	location: PropTypes.object,
	pageContext: PropTypes.object,
	previewData: PropTypes.object,
};

Article.defaultProps = {
	data: {},
	location: {},
	pageContext: {},
	previewData: {},
};
